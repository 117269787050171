import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const ExcelFileIcon: React.FC<IconProps> = (props) => (
  <Icon width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#25855A"
      d="M3 8.1C3 5.2834 5.2834 3 8.1 3h23.8C34.7167 3 37 5.2834 37 8.1v23.8c0 2.8167-2.2833 5.1-5.1 5.1H8.1C5.2834 37 3 34.7167 3 31.9V8.1Z"
    />
    <path
      fill="#fff"
      d="m15.0552 25.6667 3.2821-6.0747-3.0826-5.7483h2.9738l1.0336 2.2304c.133.266.266.5561.399.8704.145.3143.2961.6589.4533 1.0336h.0725a42.383 42.383 0 0 1 .3808-1.0336c.133-.3143.2599-.6044.3808-.8704l.943-2.2304h2.8469l-3.0645 5.8752 3.264 5.9478H21.964l-1.1787-2.4118a66.9612 66.9612 0 0 0-.4352-.9248 30.5818 30.5818 0 0 0-.4533-1.0154h-.0725c-.133.3626-.266.7011-.399 1.0154a72.5405 72.5405 0 0 0-.3989.9248l-1.1061 2.4118h-2.8651Z"
    />
  </Icon>
);
