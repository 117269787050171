import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const TradewingIcon: React.FC<IconProps> = ({ color, ...props }) => {
  return (
    <Icon
      width="632"
      height="616"
      viewBox="0 0 632 616"
      fill={color}
      {...props}
    >
      <svg
        width="632"
        height="616"
        viewBox="0 0 632 616"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2_51)">
          <path d="M632 0.350494C630.3 7.38306 630.028 12.1054 628.124 16.0124C592.149 89.9733 550.258 159.993 487.66 215.031C457.092 241.904 423.736 264.598 386.13 281.959C328.19 308.696 266.646 322.558 205.816 339.409C143.013 356.803 82.9654 379.464 37.9804 429.983C34.2401 434.162 29.4458 437.423 25.1615 441.126C23.7674 440.549 22.3733 439.971 20.9792 439.36C22.1013 432.327 22.4073 425.023 24.5155 418.33C32.948 391.762 40.4965 364.753 51.0372 339.001C71.9486 287.972 104.659 244.418 146.176 208.746C179.464 180.174 216.696 156.766 256.207 136.449C328.394 99.384 405.511 76.146 481.846 50.3599C526.049 35.4454 570.082 20.0553 614.217 5.0049C619.181 3.30621 624.383 2.32097 632 0.350494Z" />
          <path d="M218.566 582.253C225.026 575.357 228.393 570.498 232.881 567.169C268.413 540.839 286.842 502.823 303.912 463.991C322.579 421.523 341.722 378.886 375.452 346.373C397.18 325.412 422.953 308.153 448.387 291.574C486.708 266.603 530.333 253.013 573.345 238.575C578.072 236.978 583.716 238.065 588.953 237.895C588.307 242.651 588.171 247.578 586.878 252.164C580.35 275.368 573.618 298.538 566.817 321.674C547.436 387.482 513.162 443.606 459.88 487.84C417.717 522.833 369.468 545.969 317.717 562.073C291.433 570.261 264.333 575.798 237.505 582.083C232.677 583.239 227.339 582.253 218.566 582.253Z" />
          <path d="M304.422 364.074C299.73 402.464 281.878 435.962 256.717 466.233C206.461 526.74 139.579 562.786 69.0582 593.329C50.799 601.245 31.7578 607.326 12.9885 614.053C2.44781 617.858 -1.29244 614.902 0.33967 603.589C5.74603 566.557 20.605 533.127 40.3943 501.769C72.1865 451.386 119.008 419.723 173.241 398.081C211.358 382.895 249.916 368.83 288.339 354.459C300.41 349.975 305.306 352.828 304.422 364.074Z" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2_51"
            x1="326.49"
            y1="0.350494"
            x2="326.49"
            y2="441.126"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2F22CC" stopOpacity="0.79" />
            <stop offset="1" stopColor="#7469F9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2_51"
            x1="403.759"
            y1="237.69"
            x2="403.759"
            y2="582.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2F22CC" stopOpacity="0.79" />
            <stop offset="1" stopColor="#7469F9" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2_51"
            x1="152.248"
            y1="352.433"
            x2="152.248"
            y2="615.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2F22CC" stopOpacity="0.79" />
            <stop offset="1" stopColor="#7469F9" />
          </linearGradient>
          <clipPath id="clip0_2_51">
            <rect
              width="632"
              height="615.299"
              fill="white"
              transform="translate(0 0.350494)"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};
