import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const PinIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path
      d="M14.0318 8.29527L15.4883 1H10.9884H6.4884L7.84432 8.29527L5.53809 12.7697H16.2255L14.0318 8.29527Z"
      strokeWidth="1.67"
      strokeLinejoin="round"
    />
    <path
      d="M10.8818 12.7699V19.0001"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
