import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const DragAndDropIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon width="10" height="20" viewBox="0 0 10 20" fill="none" {...props}>
      <circle cx="2" cy="2" r="2" fill="#CBD5E0" />
      <circle cx="2" cy="10" r="2" fill="#CBD5E0" />
      <circle cx="2" cy="18" r="2" fill="#CBD5E0" />
      <circle cx="8" cy="2" r="2" fill="#CBD5E0" />
      <circle cx="8" cy="10" r="2" fill="#CBD5E0" />
      <circle cx="8" cy="18" r="2" fill="#CBD5E0" />
    </Icon>
  );
};
