import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const PowerPointFileIcon: React.FC<IconProps> = (props) => (
  <Icon width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#D69E2E"
      d="M3 8.1C3 5.2834 5.2834 3 8.1 3h23.8C34.7167 3 37 5.2834 37 8.1v23.8c0 2.8167-2.2833 5.1-5.1 5.1H8.1C5.2834 37 3 34.7167 3 31.9V8.1Z"
    />
    <path
      fill="#fff"
      d="M9.8268 24.5333v-8.8672h3.0464c.6528 0 1.2421.0907 1.768.272.5349.1723.961.467 1.2784.884.3173.4171.476.9838.476 1.7 0 .6891-.1587 1.2558-.476 1.7-.3174.4443-.739.7752-1.2648.9928-.5259.2086-1.1016.3128-1.7272.3128H11.826v3.0056H9.8268Zm1.9992-4.5968h.9792c1.088 0 1.632-.4714 1.632-1.4144 0-.4624-.1451-.7888-.4352-.9792-.2902-.1904-.7072-.2856-1.2512-.2856h-.9248v2.6792ZM17.9283 24.5333v-8.8672h3.0464c.6528 0 1.2422.0907 1.768.272.535.1723.9611.467 1.2784.884.3174.4171.476.9838.476 1.7 0 .6891-.1586 1.2558-.476 1.7-.3173.4443-.7389.7752-1.2648.9928-.5258.2086-1.1016.3128-1.7272.3128h-1.1016v3.0056h-1.9992Zm1.9992-4.5968h.9792c1.088 0 1.632-.4714 1.632-1.4144 0-.4624-.145-.7888-.4352-.9792-.2901-.1904-.7072-.2856-1.2512-.2856h-.9248v2.6792ZM27.5711 24.5333v-7.1808h-2.4344v-1.6864h6.8816v1.6864h-2.4344v7.1808h-2.0128Z"
    />
  </Icon>
);
