import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const DocFileIcon: React.FC<IconProps> = (props) => (
  <Icon width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#2B6CB0"
      d="M3 8.1C3 5.2834 5.2834 3 8.1 3h23.8C34.7167 3 37 5.2834 37 8.1v23.8c0 2.8167-2.2833 5.1-5.1 5.1H8.1C5.2834 37 3 34.7167 3 31.9V8.1Z"
    />
    <path
      fill="#fff"
      d="m15.0725 26.8-2.1942-11.8229h2.7382l.816 5.5306c.0725.6166.1511 1.2391.2357 1.8678.0846.6165.1632 1.2391.2357 1.8677h.0726c.1209-.6286.2357-1.2512.3445-1.8677.1209-.6287.2418-1.2512.3627-1.8678l1.2693-5.5306h2.2667l1.2693 5.5306c.1209.5924.2357 1.2029.3445 1.8315.1209.6286.2418 1.2633.3627 1.904h.0725c.0847-.6407.1632-1.2693.2358-1.8859.0846-.6286.1692-1.2451.2538-1.8496l.816-5.5306h2.5568L25.0277 26.8H21.673l-1.1605-5.3675a55.3171 55.3171 0 0 1-.272-1.3781 93.9143 93.9143 0 0 1-.1995-1.36h-.0725a110.963 110.963 0 0 1-.2358 1.36 27.4847 27.4847 0 0 1-.2538 1.3781L18.3727 26.8h-3.3002Z"
    />
  </Icon>
);
