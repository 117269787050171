import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const PDFFileIcon: React.FC<IconProps> = (props) => (
  <Icon width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#C53030"
      d="M3 8.1C3 5.2834 5.2834 3 8.1 3h23.8C34.7167 3 37 5.2834 37 8.1v23.8c0 2.8167-2.2833 5.1-5.1 5.1H8.1C5.2834 37 3 34.7167 3 31.9V8.1Z"
    />
    <path
      fill="#fff"
      d="M9.114 24.5333v-8.8672h3.0464c.6528 0 1.2421.0907 1.768.272.5349.1723.9611.467 1.2784.884.3173.4171.476.9838.476 1.7 0 .6891-.1587 1.2558-.476 1.7-.3173.4443-.7389.7752-1.2648.9928-.5259.2086-1.1016.3128-1.7272.3128h-1.1016v3.0056H9.114Zm1.9992-4.5968h.9792c1.088 0 1.632-.4714 1.632-1.4144 0-.4624-.1451-.7888-.4352-.9792-.2901-.1904-.7072-.2856-1.2512-.2856h-.9248v2.6792ZM17.2156 24.5333v-8.8672h2.5024c.9066 0 1.6909.1542 2.3528.4624.6618.3083 1.1741.7888 1.5368 1.4416.3717.6438.5576 1.4734.5576 2.4888 0 1.0155-.1814 1.8542-.544 2.516-.3627.6619-.8704 1.156-1.5232 1.4824-.6438.3174-1.4008.476-2.2712.476h-2.6112Zm1.9992-1.6184h.3808c.5077 0 .9474-.0906 1.3192-.272.3808-.1813.6754-.4805.884-.8976.2085-.417.3128-.9792.3128-1.6864 0-.7072-.1043-1.2602-.3128-1.6592-.2086-.408-.5032-.6936-.884-.8568-.3718-.1722-.8115-.2584-1.3192-.2584h-.3808v5.6304ZM25.8484 24.5333v-8.8672h5.5896v1.6864h-3.5904v2.0536h3.0736v1.6864h-3.0736v3.4408h-1.9992Z"
    />
  </Icon>
);
